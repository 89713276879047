<div class="container">



    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">
                <h5 class="mb-1 text-secondary  mb-4 text-center"> Consola
                </h5>



                <div class="mt-5 ">
                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/online']">
                            <div class="card-body">
                                <h5 class="card-title">Usuarios en linea
                                    <i class="float-end pt-2  fas fa-users"></i>

                                </h5>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="_auth.IsPermitid(['OWNER','ANALIST'])" class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/constacts-approved']">
                            <div class="card-body">
                                <h5 class="card-title">Estatus de aprobación de Usuarios
                                    <i class="float-end pt-2  fas fa-users"></i>

                                </h5>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="_auth.IsPermitid(['OWNER'])" class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/users']">
                            <div class="card-body">
                                <h5 class="card-title">Gestion de usuarios
                                    <i class="float-end pt-2  fas fa-users"></i>

                                </h5>

                            </div>
                        </div>
                    </div>
                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/export']">
                            <div class="card-body">
                                <h5 class="card-title">Reportes
                                    <i class="float-end pt-2  fas fa-file-export"></i>
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4" *ngIf="_auth.IsPermitid(['OWNER'])">
                        <div class="card" [routerLink]="['/console/notificacion-xlsx']">
                            <div class="card-body">
                                <h5 class="card-title">Notificación por Canales
                                    <i class="float-end pt-2  fas fa-file-export"></i>
                                </h5>
                            </div>
                        </div>
                    </div>
     
                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/plans']">
                            <div class="card-body">
                                <h5 class="card-title">Gestion de planes
                                    <i class="float-end pt-2 fab fa-product-hunt"></i>
                                </h5>

                            </div>
                        </div>
                    </div>

                    <div  *ngIf="_auth.IsPermitid(['OWNER'])" class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/rates']">
                            <div class="card-body">
                                <h5 class="card-title">Gestion de tasas de cambio
                                    <i class="float-end pt-2  fas fa-dollar-sign"></i>

                                </h5>

                            </div>
                        </div>
                    </div>

                    <div  *ngIf="_auth.IsPermitid(['OWNER'])" class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/accounts-admin']">
                            <div class="card-body">
                                <h5 class="card-title">Gestion de Cuentas
                                    <i class="float-end pt-2  fas fa-dollar-sign"></i>

                                </h5>

                            </div>
                        </div>
                    </div>
 
                    <div class="col-12  mt-4" *ngIf="_auth.IsPermitid(['OWNER'])">
                        <div class="card" [routerLink]="['/console/carga-masiva-cleinte-xlsx']">
                            <div class="card-body">
                                <h5 class="card-title">Carga masiva de clientes 
                                    <i class="float-end pt-2  fas fa-file-export"></i>
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4" *ngIf="_auth.IsPermitid(['OWNER'])">
                        <div class="card" [routerLink]="['/console/carga-masiva-aprobaciones-xlsx']">
                            <div class="card-body">
                                <h5 class="card-title">Carga masiva aprobaciones 
                                    <i class="float-end pt-2  fas fa-file-export"></i>
                                </h5>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<!-- 
<button type="button" class="btn btn-outline-secondary  mb-3 " [routerLink]="['/console/stadistic']">Estadística de Compras F./Pagos</button>
<button type="button" class="btn btn-outline-secondary  mb-3 " [routerLink]="['/console/stadistic_approved']">Estadística de Aprobación</button>
 -->