<div class="mt-md-3 container-fluid">
  <div class="row mt-2">
    <div class="col-12 col-md-8 offset-md-2">
      <div class="row rmt-2">
        <div class="col-12 col-md-8 offset-md-2">

            <div class="row rmt-2">
                <div class="col-12  col-md-8 offset-md-2">
                    <h5 class="mb-1 text-secondary mb-4 text-center">
                         Gestión de Cuentas
                    </h5>
                </div>
            </div>

            <div class="row rmt-2">
                <div class="col-12  ">
                            <div class="form-floating">
                                <select class="form-select" id="payment_method" [(ngModel)]="paymentMethodsSelected"  aria-label="Floating label select example" (change)="getAccountPayment()">
                                        <option *ngFor="let item of paymentMethods; let i=index" [value]="item?.code">
                                            {{ item.name }}
                                        </option>
                                </select>
                                <label for="origin_account">Métodos de Pago</label>
                </div>
            </div>


          <div *ngIf="!accountPayment_load && accountPayment?.length > 0">
            <div class="card mt-4 pb-2" *ngFor="let item of accountPayment">
              <div class="card-body card-instru p-2 cursor-pounter">
                <ng-container>
                  <div
                    class="titule-card-accion fz-14 text-capitalize fw-bold w-100 text-primary"
                  >
                    {{ item?.bank?.name }}  <span class="badge-new-green" *ngIf="item.typeConfirm==='AUTO'">Automático</span> &nbsp;<span class="badge-new-blue" *ngIf="item.showClient || item.typeConfirm==='AUTO'">Cliente</span>&nbsp;
                    <span class="badge-new" *ngIf="item?.tag">{{
                      item?.tag
                    }}</span>
                  </div>

                  <div class="d-flex mt-2" if>
                    <div class="text-secondary fz-12">Tipo</div>
                    <div class="fw-bold fz-14 ms-auto">
                      {{ item?.payment_method?.name }}
                    </div>
                  </div>

                  <div class="d-flex mt-1" if>
                    <div class="text-secondary fz-12">Moneda</div>
                    <div class="fw-bold fz-14 ms-auto">
                      {{ item?.currency?.name }}
                    </div>
                  </div>
                  <div class="d-flex mt-1" *ngIf="item?.taxId">
                    <div class="text-secondary fz-12">Rif</div>
                    <div class="fw-bold fz-14 ms-auto">
                      {{ item?.taxId }}
                    </div>
                  </div>
                  <div class="d-flex mt-1" *ngIf="item?.number">
                    <div class="text-secondary fz-12">Número</div>
                    <div class="fw-bold fz-14 ms-auto">
                      {{ item?.number }}
                    </div>
                  </div>

                  <div class="d-flex mt-1" *ngIf="item?.phone">
                    <div class="text-secondary fz-12">Teléfono</div>
                    <div class="fw-bold fz-14 ms-auto">
                      {{ item?.phone }}
                    </div>
                  </div>

                  <div class="d-flex mt-1" *ngIf="item?.email">
                    <div class="text-secondary fz-12">Email</div>
                    <div class="fw-bold fz-14 ms-auto">
                      {{ item?.email }}
                    </div>
                  </div>

                  <div class="d-flex mt-1" >
                    <div class=" ms-auto">
                        <span class="pl-2" *ngIf="item?.typeConfirm!=='AUTO'"><button type="button" class="btn btn-success btn-sm "  (click)="changeStatusVerification(item._id,'AUTO')" >Activar Auto</button></span>
                        <span class="pl-2" *ngIf="!item?.showClient && item?.typeConfirm!=='AUTO'"><button type="button" class="btn btn-success btn-sm "  (click)="changeStatus(item._id,true)">Mostrar Cliente</button></span>

                        <span class="pl-2" *ngIf="item?.typeConfirm==='AUTO'"><button type="button" class="btn btn-secondary btn-sm "  (click)="changeStatusVerification(item._id,'MANUAL')">Inactivar Auto</button></span>
                        <span class="pl-2" *ngIf="item?.showClient"><button type="button" class="btn btn-secondary btn-sm "  (click)="changeStatus(item._id,false)" >Ocultar Cliente</button></span>
                    </div>
                  </div>
                  
                  
                </ng-container>
                
              </div>
              
            </div>
            <div class="row" >
                <div class="col-12 fz-14 ms-auto text-center">
                    <hr>Por defecto las cuentas con la verificación Automática se muestran en el cliente
                </div>
              </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
